
// import { create } from "../../api/ad.js";
import { pinyin } from "pinyin-pro";
import { Plus } from "@element-plus/icons-vue";
import { client } from '@/client';
import { defineComponent } from 'vue';
import { ElMessage } from 'element-plus';
import { AdvertPlatform } from "@/shared/types/AdvertData";
import { loadFile } from "@/utils/tools";
export default defineComponent ({
  name: "ad-add",
  data: () => {
    return {
      params: {
        title: "",
        mark: "",
        imgUrl: "",
        imgPath:"",
        link: "",
        platform: "1",
        position: ["1"],
        createdAt: new Date(),
        updatedAt: new Date(),
        status: "1",
      },
      disabled: false,
      paramsRules: {
        //校验规则
        title: [
          { required: true, message: "请输入栏目名称", trigger: "blur" },
          {
            min: 2,
            max: 50,
            message: "名称长度在 2 到 50 个字符之间",
            trigger: "blur",
          },
        ],
        mark: [
          { required: true, message: "请输入栏目名称", trigger: "blur" },
          {
            min: 2,
            max: 50,
            message: "名称长度在 2 到 50 个字符之间",
            trigger: "blur",
          },
        ],
      },
    };
  },
  components: {
    Plus,
  },
  watch: {
    "params.title": function (newv, oldv) {
      this.params.mark = pinyin(newv, { toneType: "none" }).replace(/\s+/g, "");
    },
  },
  methods: {
    handleAttr(e: any) {
      console.log("e-->", e);
    },
    async onUploadChange(res: { raw: File,name:string }){
      let file = res.raw;
      console.error(res)
      console.error(file)
      let isSuss = this.beforeUpload(file);
      if(isSuss){
        let fileData = await loadFile(file);
        let ret = await client.callApi("pub/UploadImage", {
            file: fileData,
            filename: file.name
        });
        if (ret.isSucc) {
          let path = client.options.server + ret.res.path;
          this.params.imgUrl = ret.res.path;
          this.params.imgPath = path;
        } else {
              ElMessage({
                message:ret.err.message,
                type: "error",
              });
        }
      }
    },
    beforeUpload(rawFile:File) {
      console.error(rawFile)
      let isJPG = rawFile.type === 'image/jpeg';
      let isPNG = rawFile.type === 'image/png';
      let isLt2M = rawFile.size / 1024 / 1024 < 2;
      if (!isJPG && !isPNG) {
          ElMessage({
            message:"只能上传 JPG 或 PNG 格式的图片",
            type: "error",
          });
        return false;
      }
      if (!isLt2M) {
          ElMessage({
            message:"图片大小不能超过 2MB",
            type: "error",
          });
        return false;
      }
      return true;
    },
    //新增
    async create() {
      try {
        let res = await client.callApi("advert/Create",{
          ...this.params,
          position:this.params.position.sort((a, b) => {
            return parseInt(a) - parseInt(b);
          }).toString(),
          platform:this.params.platform as AdvertPlatform,
          status:parseInt(this.params.status)
        });
        if (res.isSucc) {
          ElMessage({
            message: "更新成功^_^",
            type: "success",
          });
          this.$router.go(-1);
        }
      } catch (error) {
        console.log(error);
      }
    },

    submit(formName: string|number) {
      let refs:any = this.$refs[formName];
      refs.validate((valid: any) => {
        if (valid) {
          this.create();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
});
