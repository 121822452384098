import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mr-10 ml-10 mb-20" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_radio = _resolveComponent("el-radio")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_checkbox_group = _resolveComponent("el-checkbox-group")!
  const _component_el_image = _resolveComponent("el-image")!
  const _component_Plus = _resolveComponent("Plus")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_upload = _resolveComponent("el-upload")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_form, {
      ref: "params",
      model: _ctx.params,
      rules: _ctx.paramsRules,
      "label-width": "84px"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", null, [
          _createVNode(_component_el_form_item, {
            label: "广告名称",
            prop: "title"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.params.title,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.params.title) = $event)),
                placeholder: "请输入汉字"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, { label: "广告链接" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.params.link,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.params.link) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, {
            label: "广告标识",
            prop: "mark"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.params.mark,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.params.mark) = $event)),
                placeholder: "模板使用标识"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, { label: "广告平台" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_radio, {
                modelValue: _ctx.params.platform,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.params.platform) = $event)),
                label: "1"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("PC")
                ]),
                _: 1
              }, 8, ["modelValue"]),
              _createVNode(_component_el_radio, {
                modelValue: _ctx.params.platform,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.params.platform) = $event)),
                label: "2"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("H5")
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, { label: "广告位置" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_checkbox_group, {
                modelValue: _ctx.params.position,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.params.position) = $event)),
                onChange: _ctx.handleAttr
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_checkbox, { label: "1" }, {
                    default: _withCtx(() => [
                      _createTextVNode("首页")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_checkbox, { label: "2" }, {
                    default: _withCtx(() => [
                      _createTextVNode("频道页")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_checkbox, { label: "3" }, {
                    default: _withCtx(() => [
                      _createTextVNode("列表页")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_checkbox, { label: "4" }, {
                    default: _withCtx(() => [
                      _createTextVNode("文章页")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_checkbox, { label: "5" }, {
                    default: _withCtx(() => [
                      _createTextVNode("单页")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["modelValue", "onChange"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, { label: "图片链接" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.params.imgUrl,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.params.imgUrl) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, { label: "广告图片" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_upload, {
                class: "avatar-uploader",
                "on-change": _ctx.onUploadChange,
                "auto-upload": false,
                "show-file-list": false,
                "before-upload": _ctx.beforeUpload
              }, {
                default: _withCtx(() => [
                  (_ctx.params.imgPath)
                    ? (_openBlock(), _createBlock(_component_el_image, {
                        key: 0,
                        style: {"width":"100%"},
                        src: _ctx.params.imgPath
                      }, null, 8, ["src"]))
                    : (_openBlock(), _createBlock(_component_el_icon, {
                        key: 1,
                        class: "avatar-uploader-icon"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Plus)
                        ]),
                        _: 1
                      }))
                ]),
                _: 1
              }, 8, ["on-change", "before-upload"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, { label: "发布时间" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_date_picker, {
                modelValue: _ctx.params.createdAt,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.params.createdAt) = $event)),
                type: "datetime",
                placeholder: "选择日期时间"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, { label: "是否显示" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_radio, {
                modelValue: _ctx.params.status,
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.params.status) = $event)),
                label: "1"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("发布")
                ]),
                _: 1
              }, 8, ["modelValue"]),
              _createVNode(_component_el_radio, {
                modelValue: _ctx.params.status,
                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.params.status) = $event)),
                label: "2"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("不发布")
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          })
        ]),
        _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_button, {
              type: "primary",
              onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.submit('params')))
            }, {
              default: _withCtx(() => [
                _createTextVNode("保存")
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model", "rules"])
  ]))
}